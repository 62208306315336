import { Link } from 'react-router-dom'

function NotFound() {
  return (
    <div className="not-found">
      <img className="logo large" src="/logo_round.png" alt="logo" />
      <h1>Not Found</h1>
      <p>The page you're looking for does not exist.</p>
      <Link className="button" to="/">
        Home
      </Link>
    </div>
  )
}

export default NotFound
