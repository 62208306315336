function Support() {
  return (
    <div className="support subpage">
      <header>
        <div className="logo-container">
          <img className="logo" src="/logo_round.png" alt="logo" />
          <h1>TinyFeeds</h1>
        </div>
      </header>
      <main>
        <h2>Support</h2>
        <p>
          If you are experiencing issues with TinyFeeds or you have feature
          suggestions, let us know!
        </p>
        <form action="https://formspree.io/f/mrgjgzna" method="POST">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="_replyto" />

          <label htmlFor="type">Reason</label>
          <select id="type" name="type">
            <option value="bug">Bug</option>
            <option value="suggestion">Suggestion</option>
          </select>

          <label htmlFor="message">Comments</label>
          <textarea name="message" id="message" />

          <button type="submit">Send</button>
        </form>
      </main>
    </div>
  )
}

export default Support
