import { Link } from 'react-router-dom'

function Home() {
  const cta = (
    <div className="cta">
      <a
        title="Download on the App Store"
        href="https://apps.apple.com/us/app/tinyfeeds/id1597838632"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/appstore.svg" alt="Download on the App Store" />
      </a>
    </div>
  )

  return (
    <div className="home">
      <header>
        <div className="logo-container">
          <img className="logo" src="/logo_round.png" alt="logo" />
          <h1>TinyFeeds</h1>
        </div>
        {cta}
      </header>
      <main>
        <section>
          <h2>The power of Feedly on your wrist</h2>
          <p>
            TinyFeeds brings the power of Feedly to your wrist. It is a
            full-feature app that allows you to read stories directly on your
            watch.
          </p>
          {cta}
        </section>
        <section>
          <h2>No Phone? No Problem!</h2>
          <p>
            TinyFeeds is 100% Standalone and as long as you have a Wi-Fi or
            active cellular connection, you can browse your feeds from anywhere!
            It even marks the stories you view as read automatically so when you
            get back to your phone or computer, you can skip over what you've
            already read.
          </p>
        </section>
        <section>
          <h2>Find something awesome?</h2>
          <p>
            If you find something you're interested in and want to check it out
            later, there is a handy button to add it to Saved For Later.
          </p>
        </section>
        <section>
          <h2>Features</h2>
          <ul>
            <li>Today Feed</li>
            <li>Favorites</li>
            <li>Search</li>
            <li>Complications</li>
            <li>Collections</li>
            <li>Leo Priorities</li>
            <li>Boards</li>
            <li>Recents</li>
            <li>Tags</li>
            <li>more to come!</li>
          </ul>
        </section>
      </main>
      <footer>
        <p>Copyright &copy; {new Date().getFullYear()} TinyFeeds</p>
        <p>
          <Link to="/privacy">Privacy</Link> •{' '}
          <Link to="/support">Support</Link>
        </p>
      </footer>
    </div>
  )
}

export default Home
