function Privacy() {
  return (
    <div className="privacy subpage">
      <header>
        <div className="logo-container">
          <img className="logo" src="/logo_round.png" alt="logo" />
          <h1>TinyFeeds</h1>
        </div>
      </header>
      <main>
        <h2>Privacy</h2>
        <h3>
          Expecting a lengthy legal doc? We'll keep it simple... We don't
          collect a single thing!
        </h3>
        <p>
          No trackers, no cookies, no logging, no nothing! Your privacy is
          important and we want to keep it that way. Note that{' '}
          <a
            href="https://www.apple.com/privacy/"
            title="Apple Privacy"
            target="_blank"
            rel="noreferrer noopener"
          >
            Apple
          </a>{' '}
          and{' '}
          <a
            href="https://feedly.com/i/legal/privacy"
            title="Feedly Privacy"
            target="_blank"
            rel="noreferrer noopener"
          >
            Feedly
          </a>{' '}
          may collect information like crash logs, API logs, etc. in order to
          improve their services.
        </p>
      </main>
    </div>
  )
}

export default Privacy
