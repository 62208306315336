function LoginComplete() {
  return (
    <div className="login-complete subpage">
      <header>
        <div className="logo-container">
          <img className="logo" src="/logo_round.png" alt="logo" />
          <h1>TinyFeeds</h1>
        </div>
      </header>
      <main>
        <h2>🎉</h2>
        <h3>Congrats, You're in!</h3>
        <p>
          Check your watch, you should be signed into your Feedly account. Now
          you can enjoy using Feedly on your wrist!
        </p>
      </main>
    </div>
  )
}

export default LoginComplete
